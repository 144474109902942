<template>
  <v-container id="campaigns" fluid tag="section">
    <alert-notification :message="alert" />
    <v-card>
      <v-card-title>
        {{ $t('marketing.campaign.list.subtitle') }}
        <v-spacer />
        <create-campaign-form @success="refreshData" @fail="fail" />
      </v-card-title>
      <v-data-table :headers="table.headers" :options="table.options" :items="campaigns" :search="table.search" :loading="loading">
        <template #[`item.toDate`]="{ item }">
          <span>{{ formatDate(item.toDate) }}</span>
        </template>
        <template #[`item.actions`]="{ item }">
          <span class="pr-3">
            <v-icon medium :title="$t('admin.sub.printDiscounts.action.print.title')" @click="print(item)">
              mdi-pencil
            </v-icon>
          </span>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  import { DateTime } from 'luxon';
  import { handleRequestError } from '@/services/common/Http';
  import AlertNotification from '@/views/components/common/notification/AlertNotification';
  import CreateCampaignForm from '@/views/forms/marketing/CreateCampaignForm';

  export default {
    name: 'Campaigns',
    components: { AlertNotification, CreateCampaignForm },
    data () {
      return {
        alert: '',
        loading: false,
        table: {
          headers: [
            {
              text: this.$i18n.t('marketing.campaign.list.column.channel'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'channel'
            },
            {
              text: this.$i18n.t('marketing.campaign.list.column.name'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'name'
            },
            {
              text: this.$i18n.t('marketing.campaign.list.column.from'),
              align: 'center',
              filterable: false,
              sortable: true,
              value: 'from'
            },
            {
              text: this.$i18n.t('marketing.campaign.list.column.to'),
              align: 'center',
              filterable: false,
              sortable: true,
              value: 'to'
            },
            {
              text: this.$i18n.t('marketing.campaign.list.column.state'),
              align: 'center',
              filterable: false,
              sortable: true,
              value: 'state'
            },
            {
              text: '',
              align: 'center',
              filterable: false,
              sortable: false,
              value: 'actions'
            }
          ],
          options: {
            page: 1,
            itemsPerPage: -1,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: [],
            multiSort: false,
            mustSort: true
          },
          search: ''
        },
        campaigns: []
      };
    },
    mounted: async function () {
      await this.refreshData();
    },
    methods: {
      formatDate (date) {
        return DateTime.fromISO(date, { setZone: true }).toLocaleString({ locale: 'fr-fr' });
      },
      async refreshData () {
        this.loading = true;
        try {
          // const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'discounts/distribution');
          // if (response.data !== undefined && response.data.campaigns !== undefined) {
          //   this.campaigns = response.data.campaigns;
          // }
          this.campaigns = [];

          this.alert = '';
        } catch (error) {
          this.alert = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('admin.error.fail'));
        }
        this.loading = false;
      },
      async fail (error, message) {
        this.alert = await handleRequestError(error, this.$router, this.$i18n, message);
      }
    }
  };
</script>
