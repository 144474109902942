<template>
  <div>
    <v-btn small color="primary" :loading="show || loading" :disabled="show || loading" @click.stop="show=true">
      <v-icon medium dark class="pr-2">
        mdi-label-percent
      </v-icon>
      {{ $t('marketing.campaign.form.subtitle.create') }}
    </v-btn>
    <v-dialog v-model="show" :persistent="true" :light="true" :fullscreen="true" :scrollable="false" overlay-color="tertiary" max-width="90%" class="pa-2 elevation-0" content-class="elevation-0">
      <base-material-card class="mt-6">
        <template #heading>
          <div class="display-2 font-weight-light text-uppercase">
            {{ $t('marketing.campaign.form.title') }}
          </div>
          <div class="subtitle-1 font-weight-light">
            {{ $t('marketing.campaign.form.subtitle.create') }}
            {{ $t('marketing.campaign.form.subtitle.edit') }}
          </div>
        </template>

        <v-form ref="form" v-model="valid" lazy-validation @keyup.native.enter="validate() && saveCampaign()">
          <v-container class="create-campaign" style="max-width: 1200px">
            <v-row justify="center" align="center">
              <v-col cols="2" />
              <v-col cols="6">
                <v-text-field v-model="campaignName" type="text" min="5" max="2000" required dense solo hide-details
                              :label="$t('marketing.campaign.form.field.name.title')" :disabled="loading" :loading="loading" :clearable="true"
                />
              </v-col>
              <v-col cols="2" />
            </v-row>
            <v-stepper v-model="step" flat vertical class="ma-0 pa-0 elevation-0">
              <v-stepper-step :complete="step > 1" step="1" editable>
                {{ $t('marketing.campaign.form.step.customer') }}
              </v-stepper-step>
              <v-stepper-content step="1">
                <v-card flat>
                  <v-row justify="center" align="center">
                    <v-col cols="4">
                      {{ $t('marketing.campaign.form.field.customerTarget.title') }}
                    </v-col>
                    <v-col cols="8">
                      <v-select v-model="customerTarget" item-value="id" item-text="title" :items="customerTargets" :disabled="loading" :loading="loading" required dense hide-details />
                    </v-col>
                  </v-row>
                </v-card>
                <v-btn color="secondary" class="mt-2" @click="step = 2">
                  {{ $t('marketing.campaign.form.button.continue') }}
                </v-btn>
              </v-stepper-content>

              <v-stepper-step :complete="step > 2" step="2" editable>
                {{ $t('marketing.campaign.form.step.discount') }}
                <small>{{ $t('marketing.campaign.form.step.optional') }}</small>
              </v-stepper-step>
              <v-stepper-content step="2">
                <v-card flat>
                  ...
                </v-card>
                <v-btn color="primary" @click="step = 3">
                  {{ $t('marketing.campaign.form.button.continue') }}
                </v-btn>
              </v-stepper-content>

              <v-stepper-step step="3" editable>
                {{ $t('marketing.campaign.form.step.content') }}
              </v-stepper-step>
              <v-stepper-content step="3">
                <v-card flat>
                  <v-tabs color="deep-blue accent-4" background-color="transparent" centered grow :show-arrows="false" :height="20">
                    <v-tab>
                      {{ $t('marketing.campaign.form.channel.email') }}
                    </v-tab>
                    <v-tab :disabled="true">
                      {{ $t('marketing.campaign.form.channel.sms') }}
                    </v-tab>
                    <v-tab-item>
                      <v-row justify="center" align="center">
                        <v-col cols="12">
                          <v-text-field v-model="campaignEmailObject" type="text" min="5" max="120" required dense solo hide-details
                                        :label="$t('marketing.campaign.form.field.object.title')" :disabled="loading" :loading="loading" :clearable="true"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-textarea v-model="campaignEmailBody" :counter="2000" dense auto-grow rows="8" row-height="15"
                                      :label="$t('marketing.campaign.form.field.body.title')" :disabled="loading" :loading="loading" :clearable="true"
                          />
                        </v-col>
                      </v-row>
                    </v-tab-item>
                    <v-tab-item>
                      <v-row justify="center" align="center">
                        <v-col cols="12">
                          <v-textarea v-model="campaignSmsBody" :counter="150" dense auto-grow rows="8" row-height="15" rounded
                                      :label="$t('marketing.campaign.form.field.body.title')" :disabled="loading" :loading="loading" :clearable="true"
                          />
                        </v-col>
                      </v-row>
                    </v-tab-item>
                  </v-tabs>
                </v-card>
              </v-stepper-content>
            </v-stepper>
            <div>
              <v-row justify="center" align="center">
                <v-col cols="4">
                  {{ $t('marketing.campaign.form.field.mainMedia.title') }}
                </v-col>
                <v-col cols="8">
                  <v-file-input v-model="campaignMainMedia" prepend-icon="mdi-camera" accept="image/png, image/jpeg" show-size counter :rules="campaignMainMediaRules"
                                :label="$t('marketing.campaign.form.field.mainMedia.title')" :disabled="loading" :loading="loading"
                  />
                </v-col>
                <v-col cols="4">
                  {{ $t('marketing.campaign.form.field.publishAt.title') }}
                </v-col>
                <v-col cols="8">
                  <period-selector :start-date-prop="campaignStartDate" :start-hour-prop="campaignStartHour" :end-date-prop="campaignEndDate" :end-hour-prop="campaignEndHour"
                                   :loading="loading" @update="updateCampaignPublicationPeriod"
                  />
                </v-col>
              </v-row>
            </div>
          </v-container>
          <v-card-actions>
            <v-spacer />
            <v-btn color="close" @click.stop="show=false">
              {{ $t('marketing.campaign.form.button.cancel') }}
            </v-btn>
            <v-btn :disabled="!valid || loading" :loading="loading" color="success" @click.stop="validate() && saveCampaign()">
              {{ $t('marketing.campaign.form.button.create') }} {{ $t('marketing.campaign.form.button.edit') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </base-material-card>
    </v-dialog>
  </div>
</template>

<script>
  import PeriodSelector from '@/views/components/common/PeriodSelector';

  export default {
    name: 'CreateCampaignForm',
    components: { PeriodSelector },
    props: {},
    data () {
      return {
        show: false,
        valid: true,
        loading: false,
        step: 1,
        campaignName: undefined,
        customerTarget: 'all',
        customerTargets: [
          { id: 'all', title: this.$t('marketing.campaign.form.customerTarget.all') },
          { id: 'new', title: this.$t('marketing.campaign.form.customerTarget.new') },
          { id: 'occasional', title: this.$t('marketing.campaign.form.customerTarget.occasional') },
          { id: 'vip', title: this.$t('marketing.campaign.form.customerTarget.vip') },
          { id: 'inactive', title: this.$t('marketing.campaign.form.customerTarget.inactive') }
        ],
        campaignEmailObject: '',
        campaignEmailBody: '',
        campaignSmsBody: '',
        campaignMainMedia: null,
        campaignMainMediaRules: [
          v => !v || v.size < 1000000 || this.$t('marketing.campaign.form.field.mainMedia.rule.size')
        ],
        campaignStartDate: '',
        campaignStartHour: '',
        campaignEndDate: '',
        campaignEndHour: '',
        campaignStartFullDate: null,
        campaignEndFullDate: null
      };
    },
    mounted: async function () {
      // TODO
    },
    methods: {
      async saveCampaign () {
        // console.log({
        //   name: this.campaignName,
        //   target: this.customerTarget,
        //   discount: undefined,
        //   content: {
        //     email: {
        //       object: this.campaignEmailObject,
        //       body: this.campaignEmailBody
        //     },
        //     sms: {
        //       body: this.campaignSmsBody
        //     }
        //   },
        //   // media: await this.campaignMainMedia.text(),
        //   from: this.campaignStartFullDate,
        //   to: this.campaignEndFullDate
        // });

        // this.loading = true;
        // this.show = true;
        // try {
        //   await this.$http.post(process.env.VUE_APP_BASE_API_URL + 'discount', {
        //     value: Number(this.value),
        //     valueType: this.valueType,
        //     quantity: Number(this.quantity),
        //     minimum: Number(this.minimum),
        //     usageLimit: Number(this.usageLimit),
        //     expirationInDays: Number(this.expirationInDays),
        //     campaign: String(this.campaign)
        //   }, { timeout: 60000 });
        //   this.$emit('success');
        // } catch (error) {
        //   this.$emit('fail', error, this.$i18n.t('admin.error.fail'));
        // }
        // this.loading = false;
        // this.show = false;
      },
      async updateCampaignPublicationPeriod (startDate, startHour, endDate, endHour, startFullDate, endFullDate) {
        this.campaignStartDate = startDate;
        this.campaignStartHour = startHour;
        this.campaignEndDate = endDate;
        this.campaignEndDate = endHour;
        this.campaignStartFullDate = startFullDate;
        this.campaignEndFullDate = endFullDate;
      },
      validate () {
        return this.$refs.form.validate();
      }
    }
  };
</script>
